import React from 'react';
import './App.css';

import Alert from './components/alert/alert'

import Header from './components/global/Header';
import Footer from '../src/components/global/Footer';
import Home from './components/pages/home/home';


function App() {
  return (
    <div className="App">
       <Alert/>
       {/*<Header/>*/}
       {/*<Home/>*/}  
       {/*<Footer/> */}
    </div>
  );
}

export default App;
