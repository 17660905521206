import React from "react";

function Alert() {
     return (

        <div>
            <h1>Site Em Construção</h1>
            <p>Volte mais tarde!</p>
        </div>
    
    )
}

export default Alert;